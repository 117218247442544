import React from "react"
import LayoutAsset from "../../components/LayoutAsset"
import { Link } from "gatsby"

export const AssetInfo = {
   title:"DYP THE PENGUIN",
   description: "Boost your prototype productivity using this FREE simple yet powerful character controller!",
   link:"/Assets/DypThePenguin" ,
   boxImage:"Assets/UnityTools/DypThePenguin/_Box.png",
   bannerImage:"Assets/UnityTools/DypThePenguin/_Banner.png",
   primaryButton: { 
      label: "FREE",
      href: "https://assetstore.unity.com/packages/slug/174519?aid=1101liAaC&pubref=website&utm_source=aff"
   },
   /*demos: {
    itchio: "#" 
   },*/
   images: [
       {
       original: 'Assets/UnityTools/DypThePenguin/_Box.png',
       thumbnail: 'Assets/UnityTools/DypThePenguin/_Box.png',
       originalAlt : 'Dyp The Penguin',
       thumbnailAlt: 'Dyp The Penguin'
       },
       {
         original: 'Assets/UnityTools/DypThePenguin/ScreenShot2.png',
         thumbnail: 'Assets/UnityTools/DypThePenguin/ScreenShot2.png',
         originalAlt : 'Includes Item pickup/equip/use',
         thumbnailAlt: 'Includes Item pickup/equip/use'
      },
      {
         original: 'Assets/UnityTools/DypThePenguin/ScreenShot3.png',
         thumbnail: 'Assets/UnityTools/DypThePenguin/ScreenShot3.png',
         originalAlt : 'Damage enemies and take damage',
         thumbnailAlt: 'Damage enemies and take damage'
      },
      {
         original: 'Assets/UnityTools/DypThePenguin/ScreenShot4.png',
         thumbnail: 'Assets/UnityTools/DypThePenguin/ScreenShot4.png',
         originalAlt : 'Complete Dyp\'s little adventure',
         thumbnailAlt: 'Complete Dyp\'s little adventure'
      },
    ],
   documentationLink:"/Documentation/DypThePenguin/Introduction",
   //forumLink:"/Forum/",
   videosLink:"https://www.youtube.com/watch?v=C8ZusDpBZjc&feature=youtu.be"
}

export default () => (
  <LayoutAsset  assetInfo={AssetInfo}>
     <h1>Dyp The Penguin</h1>

     <h3>This free asset is available for free on the asset store!</h3>

     <p>
         Boost your prototype productivity using this FREE simple yet powerful character controller!
      </p>
      <p>
        The character controller allows you to interact, damage objects and use items. It is simple yet effective, perfect for prototyping. And on top of that you get a cute penguin with some great animations and custom shader
      </p>
  </LayoutAsset>
)